import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { head, drop } from '../../lib/nodash';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '../Modal';
import { setErrors } from '../../state/cart/cartSlice';

const ModalUgcDisplay = () => {
  const dispatch = useDispatch();
  const cartErrors = useSelector((state) => state.cart.errors);
  const currentError = head(cartErrors);

  const handleClose = useCallback(() => {
    dispatch(setErrors(drop(1, cartErrors)));
  });

  return (
    <Modal
      onClose={handleClose}
      title={currentError.title}
      body={currentError.body}
      ctas={currentError.ctas}
    />
  );
};

ModalUgcDisplay.propTypes = {
  onClose: PropTypes.func,
  placement: PropTypes.object,
};

export default ModalUgcDisplay;
